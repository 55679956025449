import { render, staticRenderFns } from "./Daigo.vue?vue&type=template&id=115e8352&scoped=true"
import script from "./Daigo.vue?vue&type=script&lang=js"
export * from "./Daigo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115e8352",
  null
  
)

export default component.exports